"use strict";
// Class definition

var KTDropzoneDemo = function () {
  // Private functions
  var demo1 = function () {
    // single file upload
    $('#kt_dropzone_1').dropzone({
      url: "https://keenthemes.com/scripts/void.php", // Set the url for your upload script location
      paramName: "file", // The name that will be used to transfer the file
      maxFiles: 1,
      maxFilesize: 5, // MB
      addRemoveLinks: true,
      accept: function (file, done) {
        if (file.name == "justinbieber.jpg") {
          done("Naha, you don't.");
        } else {
          done();
        }
      }
    });
    $('#kt_dropzone_2').dropzone({
      url: "https://keenthemes.com/scripts/void.php", // Set the url for your upload script location
      paramName: "file", // The name that will be used to transfer the file
      maxFiles: 1,
      maxFilesize: 5, // MB
      addRemoveLinks: true,
      accept: function (file, done) {
        if (file.name == "justinbieber.jpg") {
          done("Naha, you don't.");
        } else {
          done();
        }
      }
    });
    $('#kt_dropzone_3').dropzone({
      url: "https://keenthemes.com/scripts/void.php", // Set the url for your upload script location
      paramName: "file", // The name that will be used to transfer the file
      maxFiles: 1,
      maxFilesize: 5, // MB
      addRemoveLinks: true,
      accept: function (file, done) {
        if (file.name == "justinbieber.jpg") {
          done("Naha, you don't.");
        } else {
          done();
        }
      }
    });
    $('#kt_dropzone_4').dropzone({
      url: "https://keenthemes.com/scripts/void.php", // Set the url for your upload script location
      paramName: "file", // The name that will be used to transfer the file
      maxFiles: 1,
      maxFilesize: 5, // MB
      addRemoveLinks: true,
      accept: function (file, done) {
        if (file.name == "justinbieber.jpg") {
          done("Naha, you don't.");
        } else {
          done();
        }
      }
    });


  }


  return {
    // public functions
    init: function () {
      demo1();
    }
  };
}();

window.onload = function () {
  KTDropzoneDemo.init();
  KTProjectsAdd.init();
};
